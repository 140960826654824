html {
    scroll-behavior: smooth;
}

:focus,
button:focus,
.btn:focus,
.btn-sm:focus {
    outline: 2px solid rgba(#0070F4, 0.5);
}

// Hamburger button
.hamburger {

    svg>* {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            transform-origin: center;
        }

        &:nth-child(1) {
            transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
        }

        &:nth-child(2) {
            transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &:nth-child(3) {
            transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
        }
    }

    &.active {

        svg>* {

            &:nth-child(1) {
                opacity: 0;
                y: 11;
                transform: rotate(225deg);
                transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
            }

            &:nth-child(2) {
                transform: rotate(225deg);
                transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &:nth-child(3) {
                y: 11;
                transform: rotate(135deg);
                transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
            }
        }
    }
}

.blur {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

// Pulsing animation
@keyframes pulseLoop {
    0% {
        opacity: .15;
        transform: scale(1) translateZ(0);
    }

    30% {
        opacity: .15;
    }

    60% {
        opacity: 0;
    }

    80% {
        opacity: 0;
        transform: scale(1.8) translateZ(0);
    }
}

@keyframes pulseMiniLoop {
    0% {
        opacity: 0;
        transform: scale(1) translateZ(0);
    }

    30% {
        opacity: .3;
    }

    50% {
        opacity: .3;
    }

    80% {
        opacity: 0;
        transform: scale(3) translateZ(0);
    }
}

.pulse {
    transform: scale(1);
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 10000ms linear infinite;
}

.pulse-mini {
    animation: pulseMiniLoop 6000ms linear infinite;
}

.pulse-1 {
    animation-delay: -3000ms;
}

.pulse-2 {
    animation-delay: -6000ms;
}

// Animations delay
.animation-delay-500 {
    animation-delay: 500ms !important;
}

.animation-delay-1000 {
    animation-delay: 1000ms !important;
}

.translate-z-0 {
    transform: translateZ(0);
}

// Custom AOS animations
[data-aos="zoom-y-out"] {
    transform: scaleX(1.03);
    opacity: 0;
    transition-property: transform, opacity;
}

.landing-page-image {
    width: -webkit-fill-available;
    height: 1000px;
}

.backgroun-page-image {
    width: -webkit-fill-available;
}

.try-now-button {
    width: 180px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 10px;
    border-top: 3px solid #FFF;
    border-right: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    border-left: 3px solid #FFF;
    box-shadow: 0px 6px 12px 0px rgba(85, 144, 214, 0.15);
}

.used-by-card {
    width: 80%;
    border-radius: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    background: #FFF;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 30;
    bottom: 100px;
    left: 10%;

    @media (max-width: 768px) {
        width: 100%;
        left: 0%;
    }
}

.used-by-small-card {
    border-radius: 10px;
    background: #08182A;
    box-shadow: 0px 2px 2px 0px rgba(133, 133, 133, 0.16) inset;
}

.super-app-card {
    border-radius: 10px;
    border-top: 1px solid #08182A;
    border-right: 3px solid #08182A;
    border-bottom: 3px solid #08182A;
    border-left: 1px solid #08182A;
    background: #FCFDFF;
}

.start-now-button {
    border-radius: 10px;
    border-bottom: 2px solid #2C8DFF;
    background: #031D3B;
    box-shadow: 0px 6px 12px 0px rgba(85, 144, 214, 0.15);
    width: 180px;
    height: 56px;
    flex-shrink: 0;
    color: #FFF;
}

.start-button {
    bottom: 65px;
    display: flex;
    justify-content: center;
}

.integrate-button {
    border-radius: 10px;
    background: #2C8DFF;
    box-shadow: 0px 6px 12px 0px rgba(85, 144, 214, 0.15);
    width: 192px;
    height: 56px;
    flex-shrink: 0;
}

@media (max-width: 768px) {
    .rotate-45-responsive {
        transform: rotate(-85deg) scaleX(-1);
    }
}

@media (max-width: 768px) {
    .benefits-responsive {
        display: flex;
        justify-content: center;
    }
}